<template>
  <div>
    <div>
      <v-data-table
        fixed-header
        v-sortable-table="{ onEnd: sortTheHeadersAndUpdateTheKey }"
        dense
        :items="items"
        :item-key="kid"
        :headers="selection"
        :search="search"
        loading-text="Chargement en cours ... Merci de patienter"
        v-model="selectedRows"
        :options.sync="options"
        multi-sort
        :disable-sort="disablesort"
        :items-per-page="ipg"
        :show-expand="showexp"
        :expanded.sync="expanded"
        single-expand
        no-data-text="Aucune Donnée!"
        ref="refName"
        :loading="laoding"
        :key="klist"
        :single-select="!multiple"
        :show-select="multiple"
        @toggle-select-all="select_all"
        @current-items="getFiltered"
        :server-items-length="total_items"
        :hide-default-footer="hdf"
        :group-by="groupby"
      >
        <template v-slot:top v-if="istop">
          <v-toolbar flat>
            <v-menu
              :close-on-content-click="false"
              transition="fab-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  v-if="master"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list
                dense
                min-width="200"
                class="px-4 scroll"
                max-height="700"
              >
                <v-list-item
                  v-for="(item, i) in headers.filter(
                    (elm) => elm.hiden != true
                  )"
                  :key="i"
                >
                  <v-list-item-action>
                    <v-checkbox v-model="item.selected"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-title>{{
                    item.text ? item.text : ""
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-toolbar-title>{{ title }} </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              autocomplete="off"
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
              @click="search_click"
              v-if="master"
            >
            </v-text-field>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="clickA"
                  v-bind="attrs"
                  v-on="on"
                  v-if="if_A"
                  :disabled="btnA_disa"
                  :loading="btnA_load"
                >
                  <v-icon> mdi-swap-horizontal-bold </v-icon>
                </v-btn>
              </template>
              <span>{{ btnA }}</span>
            </v-tooltip>

            <v-btn
              text
              @click="clickB"
              v-if="btnB"
              :disabled="btnB_disa"
              :loading="btnB_load"
            >
              {{ btnB }}
            </v-btn>

            <template>
              <upload-excel-component
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                v-if="imp_excel && isClosed"
              />
            </template>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ref="excelexport"
                  icon
                  color="blue"
                  v-if="exp_excel"
                  @click="handleDownload"
                  v-bind="attrs"
                  v-on="on"
                  :loading="downloadLoading"
                >
                  <v-icon> mdi-file-excel </v-icon>
                </v-btn>
              </template>
              <span>Export To Excel (Alt+e)</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="itemnew"
                  v-on:keyup.enter="itemnew"
                  :disabled="!add_disable || !isClosed"
                  v-if="add"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Ajouter(+)</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="btn_update_click"
                  v-if="btn_update"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> {{ btn_update_icon }} </v-icon>
                </v-btn>
              </template>
              <span>{{ btn_update_tip }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="delRecord"
                  :disabled="del_disable || !selecteditem[kid] || !isClosed"
                  v-if="del"
                  v-bind="attrs"
                  v-on="on"
                  :loading="loading_del"
                >
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
              </template>
              <span>Supprimer(-)</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="print_document"
                  v-if="print"
                  :disabled="items ? items.length == 0 : true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-printer </v-icon>
                </v-btn>
              </template>
              <span>Imprimer</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="valider"
                  v-if="valid"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-check-bold </v-icon>
                </v-btn>
              </template>
              <span>Valider</span>
            </v-tooltip>

            <template>
              <confirmdialog ref="confirm" />
            </template>
          </v-toolbar>
          <v-form ref="form" v-model="valid2" lazy-validation>
            <tr v-if="!isClosed">
              <td
                valign="middle"
                v-for="(header, columnIndex) in headers.filter(
                  (elm) => elm.edit == true
                )"
                :key="columnIndex"
              >
                <v-text-field
                  autocomplete="off"
                  v-if="header.type == 'text'"
                  v-model="editeditem[header.value]"
                  :label="header.text"
                  :rules="header.rules"
                  :readonly="!Update && editeditem.id > 0"
                  dense
                  outlined
                  @focus="$event.target.select()"
                ></v-text-field>
                <v-text-field
                  autocomplete="off"
                  v-if="header.type == 'qte'"
                  v-model.number="editeditem[header.value]"
                  :label="header.text"
                  :rules="[
                    (v) =>
                      !!v || header.oblig == false || 'Quantité obligatoire',
                    (v) =>
                      v <= editeditem.qte_max ||
                      editeditem.qte_max == null ||
                      'Quantité Max: ' + editeditem.qte_max,
                    (v) =>
                      v <= editeditem.qte_min ||
                      editeditem.qte_min == null ||
                      'Quantité Min: ' + editeditem.qte_min,
                  ]"
                  :readonly="!Update && editeditem.id > 0"
                  type="number"
                  dense
                  outlined
                  hide-spin-buttons
                  @focus="$event.target.select()"
                ></v-text-field>
                <v-text-field
                  autocomplete="off"
                  v-if="header.type == 'number'"
                  v-model.number="editeditem[header.value]"
                  :label="header.text"
                  :rules="header.rules"
                  :readonly="!Update && editeditem.id > 0"
                  type="number"
                  dense
                  outlined
                  hide-spin-buttons
                  @focus="$event.target.select()"
                ></v-text-field>
                <v-textarea
                  v-if="header.type == 'area'"
                  v-model="editeditem[header.value]"
                  :label="header.text"
                  :rules="header.rules"
                  :readonly="!Update && editeditem.id > 0"
                  dense
                  outlined
                ></v-textarea>
                <v-select
                  v-else-if="header.type == 'select'"
                  v-model="editeditem[header.value]"
                  :items="header.select_list"
                  :item-text="header.item_text"
                  :item-value="header.item_value"
                  :label="header.text"
                  :rules="header.rules"
                  :chips="header.chips"
                  :readonly="!Update && editeditem.id > 0"
                  dense
                  outlined
                  :multiple="header.multiple"
                ></v-select>
                <v-checkbox
                  v-else-if="header.type == 'check-box'"
                  v-model="editeditem[header.value]"
                  :label="header.text"
                  :readonly="!Update && editeditem.id > 0"
                ></v-checkbox>

                <v-text-field
                  v-else-if="header.type == 'phone'"
                  outlined
                  dense
                  v-model="editeditem[header.value]"
                  :rules="[(v) => !!v || 'Numero obligatoire']"
                  :label="header.text"
                  :readonly="!Update && editeditem.id > 0"
                  @focus="$event.target.select()"
                ></v-text-field>
                <datepicker
                  v-else-if="header.type == 'date'"
                  :label="header.text"
                  v-model="editeditem[header.value]"
                  :edit="Update"
                ></datepicker>

                <v-text-field
                  autocomplete="off"
                  v-if="header.type == 'color'"
                  v-model="color"
                  label="Couleur"
                  hide-details
                  solo
                  class="ma-0 pa-0"
                  dense
                  outlined
                  :readonly="!Update && editeditem.id > 0"
                >
                  <template v-slot:append>
                    <v-menu
                      v-model="menu"
                      top
                      nudge-bottom="105"
                      nudge-left="16"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card>
                        <v-card-text
                          class="pa-0"
                          v-if="Update || editeditem.id < 0"
                        >
                          <v-color-picker
                            v-model="color"
                            flat
                            :disabled="!Update && editeditem.id > 0"
                            @input="colinput"
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </td>
              <td>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-btn color="indigo" class="my-4 mx-1" @click="save" small>
                      <v-icon> mdi-content-save-outline </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-btn
                      color="indigo"
                      class="my-4 mx-1"
                      @click="isClosed = true"
                      small
                    >
                      <v-icon> mdi-close-box-outline </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </v-form>
        </template>

        <template v-slot:header="{}" v-if="secondligne">
          <thead class="v-data-table-header">
            <tr>
              <th
                valign="middle"
                v-for="(header, columnIndex) in secondligne"
                :key="columnIndex"
                :colspan="header.colspan"
                class="text-center parent-header"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, index, expand, isExpanded }">
          <tr
            :class="
              selecteditem[kid] == item[kid] && !multiple ? 'selected' : ''
            "
            @click="
              rowClicked(
                item,
                index + (options.page - 1) * options.itemsPerPage
              )
            "
            :key="row_key + index"
          >
            <td v-if="multiple">
              <v-simple-checkbox
                v-model="item.selected"
                style="margin: 0px; padding: 0px"
                :ripple="false"
                @input="checkbox_change"
                hide-details
              >
              </v-simple-checkbox>
            </td>

            <td v-if="showexp">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-if="item[val_exp]"
                    icon
                    @click="expand(!isExpanded)"
                    ><v-icon>mdi-menu-down</v-icon></v-btn
                  >
                </template>
                <span>{{ mes_exp }}</span>
              </v-tooltip>
            </td>

            <td v-for="(header, columnIndex) in selection" :key="columnIndex">
              <div v-if="header.value == 'no'">
                <v-layout>
                  {{
                    index +
                    1 +
                    (page ? page - 1 : options.page - 1) * options.itemsPerPage
                  }}
                </v-layout>
              </div>

              <v-icon
                v-if="
                  header.value == 'delete' &&
                  (del || $store.state.isadmin) &&
                  ((header.column && item[header.column] == header.valeur) ||
                    header.column == null)
                "
                small
                class="mr-2"
                @click="
                  delRecord(
                    item,
                    index + (options.page - 1) * options.itemsPerPage
                  )
                "
              >
                mdi-delete
              </v-icon>

              <!-- colonne btn -->

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="
                      header.slot == 'actions' &&
                      ((header.column &&
                        item[header.column] != header.column) ||
                        header.column == null)
                    "
                    v-bind="attrs"
                    v-on="on"
                    class="my-1"
                    color="success"
                    fab
                    dark
                    @click="btnclick(item)"
                  >
                    {{ header.icon ? header.icon : "mdi-pencil" }}
                  </v-icon>
                </template>
                <span>{{ header.tooltip ? header.tooltip : null }}</span>
              </v-tooltip>

              <v-icon
                class="my-1"
                v-if="
                  header.slot == 'col_btn1' &&
                  ((header.column &&
                    (header.operator
                      ? item[header.column] != header.valeur
                      : item[header.column] == header.valeur)) ||
                    header.column == null)
                "
                :color="
                  header.icon1
                    ? item[header.value1]
                      ? 'black'
                      : 'purple'
                    : 'green'
                "
                @click="col_btn1_click(item)"
              >
                {{
                  header.icon1
                    ? item[header.value1]
                      ? header.icon1
                      : header.icon2
                    : header.icon
                    ? header.icon
                    : "mdi-pencil"
                }}
              </v-icon>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="my-1"
                    v-if="
                      header.slot == 'col_btn2' &&
                      ((header.column &&
                        (header.operator
                          ? item[header.column] != header.valeur
                          : item[header.column] == header.valeur)) ||
                        header.column == null)
                    "
                    fab
                    dark
                    color="purple"
                    x-small
                    @click="col_btn2_click(item)"
                  >
                    <v-icon dark>
                      {{ header.icon ? header.icon : "mdi-pencil" }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ header.tooltip ? header.tooltip : null }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="my-1"
                    v-if="
                      header.slot == 'col_btn3' &&
                      ((header.column &&
                        (header.operator
                          ? item[header.column] != header.valeur
                          : item[header.column] == header.valeur)) ||
                        header.column == null)
                    "
                    fab
                    dark
                    color="purple"
                    x-small
                    @click="col_btn3_click(item)"
                  >
                    <v-icon dark>
                      {{ header.icon ? header.icon : "mdi-pencil" }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ header.tooltip ? header.tooltip : null }}</span>
              </v-tooltip>
              <div
                v-if="
                  header.slot == 'col_btn4' &&
                  ((header.column1 &&
                    (header.operator1
                      ? item[header.column1] != header.valeur1
                      : item[header.column1] == header.valeur1)) ||
                    header.column1 == null) &&
                  ((header.column2 &&
                    (header.operator2
                      ? item[header.column2] != header.valeur2
                      : item[header.column2] == header.valeur2)) ||
                    header.column2 == null)
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      :color="
                        item[header.color] ? item[header.color] : 'purple'
                      "
                      @click="col_btn4_click(item)"
                    >
                      <v-icon>
                        {{ header.icon ? header.icon : "mdi-pencil" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ header.tooltip ? header.tooltip : null }}</span>
                </v-tooltip>
              </div>
              <v-menu
                offset-y
                v-if="header.slot == 'dropdown' && item[header.value]"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="
                      item[header.color] ? item[header.color] : 'blue darken-1'
                    "
                    small
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item[header.value] }}
                  </v-btn>
                </template>

                <v-list v-if="item[header.column] != header.valeur">
                  <v-list-item-group
                    v-model="Item_list_selected"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item2, index) in header.dd_items"
                      :key="index"
                      @click="dropdownclick(item, item2)"
                    >
                      <v-list-item-title>{{ item2.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <downloadcomp
                :item="item"
                :field="header.value"
                v-else-if="header.slot == 'upload/download'"
                :can_upload="
                  header.upload &&
                  (header.column2
                    ? item[header.column2] == header.valeur2
                    : true)
                "
                :upload_type="header.upload_type"
                :can_preview="header.preview"
                :sh_upload="header.sh_upload"
                :sh_delete="header.sh_delete"
                :sh_preview="item[header.column1] == header.valeur1"
                :can_download="header.download"
                :box_filter="header.box_filter ? header.box_filter : null"
                :can_delete="
                  header.delete &&
                  (header.column2
                    ? item[header.column2] == header.valeur2
                    : true)
                "
                :update_query="header.update_query"
                :var_name="header.var_name"
                @file_uploaded="file_uploaded"
                @file_deleted="file_deleted"
              >
              </downloadcomp>

              <div v-else-if="header.hoover_list2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">{{ item[header.value] }}</v-btn>
                  </template>

                  <v-expand-transition
                    v-if="
                      header.hoover_list2
                        ? item[header.hoover_list2].length > 0
                        : false
                    "
                  >
                    <div>
                      <div
                        v-for="(item, i) in item[header.hoover_list2]"
                        :key="i"
                      >
                        {{
                          item[header.hoover_name] +
                          " : " +
                          item[header.hoover_value]
                        }}
                      </div>
                    </div>
                  </v-expand-transition>
                </v-tooltip>
              </div>
              <div
                :align="header.align ? header.align : 'left'"
                v-else-if="header.hoover"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">{{
                      typeof item[header.value] == "number"
                        ? numberWithSpace(
                            parseFloat(item[header.value]).toFixed(2)
                          )
                        : item[header.value]
                    }}</v-btn>
                  </template>

                  <v-expand-transition>
                    <div>
                      <div
                        v-for="(item1, i) in headers.filter(
                          (elm) => elm.hoover_list == true
                        )"
                        :key="i"
                      >
                        {{
                          item1.text +
                          " : " +
                          (typeof item[item1.value] == "number"
                            ? numberWithSpace(
                                parseFloat(item[item1.value]).toFixed(2)
                              )
                            : item[item1.value])
                        }}
                      </div>
                    </div>
                  </v-expand-transition>
                </v-tooltip>
              </div>
              <div v-else-if="header.slot == 'text_icon'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      dark
                      v-if="
                        item[header.column] != header.valeur &&
                        item[header.column] > 0
                      "
                    >
                      {{ header.icon ? header.icon : "mdi-pencil" }}
                    </v-icon>

                    <span v-else>
                      {{ item[header.value] }}
                    </span>
                  </template>
                  <v-expand-transition
                    v-if="
                      header.hoover_list
                        ? item[header.hoover_list]
                          ? item[header.hoover_list].length > 0
                          : false
                        : false
                    "
                  >
                    <div>
                      <div
                        v-for="(item, i) in item[header.hoover_list]"
                        :key="i"
                      >
                        {{
                          item[header.hoover_name] +
                          (item[header.hoover_value]
                            ? " : " + item[header.hoover_value]
                            : "")
                        }}
                      </div>
                    </div>
                  </v-expand-transition>
                </v-tooltip>
              </div>

              <v-btn
                :color="
                  item[header.color] ? item[header.color] : 'blue darken-1'
                "
                v-else-if="header.slot == 'btn' && item[header.value]"
                small
                rounded
                class="mr-2"
                @click="btnclick(item)"
                width="50px"
              >
                {{ item[header.value] }}
              </v-btn>

              <div
                class="green--text text-h6"
                v-else-if="header.slot == 'numberedit'"
                align="right"
              >
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  large
                  persistent
                >
                  <div>
                    {{
                      item[header.value]
                        ? numberWithSpace(
                            parseFloat(item[header.value]).toFixed(
                              typeof header.dec == "number"
                                ? header.dec
                                : typeof header.dec == "string"
                                ? item[header.dec]
                                : 2
                            )
                          )
                        : parseFloat("0").toFixed(
                            typeof header.dec == "number"
                              ? header.dec
                              : typeof header.dec == "string"
                              ? item[header.dec]
                              : 2
                          )
                    }}
                  </div>

                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      {{
                        item[header.value]
                          ? numberWithSpace(
                              parseFloat(item[header.value]).toFixed(
                                typeof header.dec == "number"
                                  ? header.dec
                                  : typeof header.dec == "string"
                                  ? item[header.dec]
                                  : 2
                              )
                            )
                          : parseFloat("0").toFixed(
                              typeof header.dec == "number"
                                ? header.dec
                                : typeof header.dec == "string"
                                ? item[header.dec]
                                : 2
                            )
                      }}
                    </div>
                    <v-text-field
                      autocomplete="off"
                      v-model="item[header.value]"
                      label="Edit"
                      single-line
                      @focus="$event.target.select()"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>

              <div v-else-if="header.slot == 'rlink'">
                <a
                  @click="
                    routeto(
                      item,
                      index + (options.page - 1) * options.itemsPerPage,
                      header.router
                    )
                  "
                  >{{ item[header.value] }}</a
                >
              </div>

              <a
                @click="
                  editItem(
                    item,
                    index + (options.page - 1) * options.itemsPerPage,
                    item[header.value]
                  )
                "
                v-else-if="
                  header.slot == 'href99' && item[header.column] == null
                "
              >
                {{ item[header.value] }}
              </a>

              <a
                @click="
                  editItem(
                    item,
                    index + (options.page - 1) * options.itemsPerPage,
                    item[header.value],
                    header.value
                  )
                "
                v-else-if="header.slot == 'href'"
              >
                {{ item[header.value] }}
              </a>

              <div
                v-else-if="header.slot == 'href22'"
                align="right"
                class="cur4"
              >
                <a
                  @click="
                    editItem(
                      item,
                      index + (options.page - 1) * options.itemsPerPage,
                      item[header.value],
                      header.value
                    )
                  "
                >
                  {{
                    numberWithSpace(
                      parseFloat(
                        item[header.value] ? item[header.value] : 0
                      ).toFixed(
                        typeof header.dec == "number"
                          ? header.dec
                          : typeof header.dec == "string"
                          ? item[header.dec]
                          : 2
                      )
                    )
                  }}
                </a>
              </div>

              <div
                align="right"
                v-else-if="header.slot == 'href2' && item[header.value] > 0"
              >
                <a
                  @click="
                    clickhrf(
                      item,
                      index + (options.page - 1) * options.itemsPerPage,
                      header.value
                    )
                  "
                  class="cur4"
                >
                  {{
                    numberWithSpace(
                      parseFloat(item[header.value]).toFixed(
                        typeof header.dec == "number"
                          ? header.dec
                          : typeof header.dec == "string"
                          ? item[header.dec]
                          : 2
                      )
                    )
                  }}
                </a>
              </div>

              <div
                align="right"
                v-else-if="header.slot == 'href2' && item[header.value] == 0"
                class="cur4"
              >
                {{
                  parseFloat(item[header.value]).toFixed(
                    typeof header.dec == "number"
                      ? header.dec
                      : typeof header.dec == "string"
                      ? item[header.dec]
                      : 2
                  )
                }}
              </div>
              <div
                align="right"
                v-else-if="header.slot == 'href3' && item[header.value] > 0"
              >
                <a
                  @click="
                    clickhrf(
                      item,
                      index + (options.page - 1) * options.itemsPerPage,
                      header.value
                    )
                  "
                  class="cur4"
                >
                  {{
                    numberWithSpace(
                      parseFloat(item[header.value]).toFixed(
                        typeof header.dec == "number"
                          ? header.dec
                          : typeof header.dec == "string"
                          ? item[header.dec]
                          : 2
                      )
                    )
                  }}
                </a>
              </div>
              <div
                align="right"
                v-else-if="header.slot == 'href3' && item[header.value] == 0"
                class="cur4"
              ></div>
              <div
                align="right"
                v-else-if="
                  header.slot == 'checked' &&
                  item[header.column] == header.valeur
                "
                class="cur4"
              >
                {{ item[header.value] }}
                <v-icon class="px-1" color="blue">mdi-check</v-icon>
              </div>

              <div
                v-else-if="
                  header.slot == 'preview' && item[header.preview] == 1
                "
              >
                <a
                  @click="
                    clickhrf(
                      item,
                      index + (options.page - 1) * options.itemsPerPage,
                      header.value
                    )
                  "
                >
                  {{ item[header.value] }}
                </a>
              </div>

              <div align="right" v-else-if="header.slot == 'href3'">
                <a
                  @click="
                    clickhrf(
                      item,
                      index + (options.page - 1) * options.itemsPerPage,
                      header.value
                    )
                  "
                  class="cur4"
                >
                  {{
                    numberWithSpace(
                      parseFloat(item[header.value]).toFixed(
                        typeof header.dec == "number"
                          ? header.dec
                          : typeof header.dec == "string"
                          ? item[header.dec]
                          : 2
                      )
                    )
                  }}
                </a>
              </div>
              <div align="left" v-else-if="header.slot == 'rline'">
                <span v-html="item[header.value].replace(/\n/g, '<br>')"></span>
              </div>

              <v-chip
                v-else-if="header.slot == 'chip'"
                :color="item[header.color]"
                small
                >{{ item[header.value] }}</v-chip
              >

              <div v-else-if="header.slot == 'chips'">
                <v-chip
                  v-for="(item3, index) in item[header.value]"
                  :key="index"
                  :color="header.color"
                  small
                  class="ma-1"
                  :close="show_chips_delete"
                  @click="chips_click(item)"
                  @click:close="chips_delete(item, item3)"
                  >{{ item3 }}</v-chip
                >
                <v-icon
                  small
                  class="ma-1"
                  v-if="!item[header.value] || item[header.value].length == 0"
                  @click="chips_click(item)"
                >
                  mdi-pencil
                </v-icon>
              </div>

              <v-simple-checkbox
                disabled
                v-model="item[header.value]"
                v-else-if="header.slot == 'checkbox'"
                >{{
              }}</v-simple-checkbox>

              <v-select
                v-else-if="header.slot == 'select'"
                v-model="item[header.value]"
                :items="header.select_list"
                :item-text="header.item_text"
                :item-value="header.item_value"
                :multiple="header.multiple"
                small-chips
                append-icon=""
                readonly
                solo
                class="pt-5"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index <= 8"
                    :color="item[header.item_color]"
                    small
                  >
                    <span>{{ item[header.item_text] }}</span>
                  </v-chip>
                  <span v-if="index === 8" class="grey--text text-caption">
                    (+{{
                      (item[header.value] ? item[header.value].length : 0) - 8
                    }}
                    autres)
                  </span>
                </template>
              </v-select>

              <div
                v-else-if="
                  header.slot == 'simple-select' ||
                  header.slot == 'autocomplete'
                "
              >
                {{
                  header.show_list
                    ? header.show_list.find(
                        (elm) => elm[header.item_value] == item[header.value]
                      )
                      ? header.show_list.find(
                          (elm) => elm[header.item_value] == item[header.value]
                        )[header.item_text]
                      : ""
                    : ""
                }}
              </div>

              <v-progress-linear
                v-else-if="header.slot == 'progress'"
                :value="item[header.value]"
                color="light-green darken-2"
                height="25"
                rounded
              >
                <template v-slot:default>
                  <strong>{{ item[header.value] }}%</strong>
                </template>
              </v-progress-linear>

              <div v-else-if="header.slot == 'cur33'" align="right">
                {{
                  typeof item[header.value] == "number"
                    ? numberWithSpace(
                        parseFloat(item[header.value]).toFixed(
                          typeof header.dec == "number"
                            ? header.dec
                            : typeof header.dec == "string"
                            ? item[header.dec]
                            : 2
                        )
                      )
                    : ""
                }}
              </div>

              <div
                v-else-if="header.slot == 'cur'"
                :class="
                  (header.cloture == true && item.cloture
                    ? 'clotured'
                    : header.expire == true && item.expire
                    ? 'expired'
                    : 'mt-1') +
                  (header.barre &&
                  header.column &&
                  item[header.column] == header.valeur
                    ? 'text-decoration-line-through'
                    : '')
                "
                align="right"
              >
                {{
                  typeof item[header.value] == "number"
                    ? numberWithSpace(
                        parseFloat(item[header.value]).toFixed(
                          typeof header.dec == "number"
                            ? header.dec
                            : typeof header.dec == "string"
                            ? item[header.dec]
                            : 2
                        )
                      )
                    : parseFloat("0").toFixed(
                        typeof header.dec == "number"
                          ? header.dec
                          : typeof header.dec == "string"
                          ? item[header.dec]
                          : 2
                      )
                }}
              </div>

              <div v-else-if="header.slot == 'number'" align="center">
                {{
                  typeof item[header.value] == "number"
                    ? numberWithSpace(parseFloat(item[header.value]).toFixed(0))
                    : "0"
                }}
              </div>

              <div v-else-if="header.slot == 'cur22'" align="right">
                {{
                  typeof item[header.value] == "number"
                    ? numberWithSpace(
                        parseFloat(item[header.value]).toFixed(
                          typeof header.dec == "number"
                            ? header.dec
                            : typeof header.dec == "string"
                            ? item[header.dec]
                            : 2
                        )
                      )
                    : parseFloat("0").toFixed(
                        typeof header.dec == "number"
                          ? header.dec
                          : typeof header.dec == "string"
                          ? item[header.dec]
                          : 2
                      )
                }}
              </div>

              <div
                v-else-if="header.slot == 'barre'"
                :class="
                  (header.cloture == true && item.cloture
                    ? 'clotured'
                    : header.expire == true && item.expire
                    ? 'expired'
                    : '') +
                  (header.barre &&
                  header.column &&
                  item[header.column] == header.valeur
                    ? ' text-decoration-line-through'
                    : '')
                "
              >
                {{ item[header.value] }}
              </div>

              <div v-else-if="header.slot == 'date'">
                {{ datefr(item[header.value]) }}
              </div>

              <div v-else-if="header.value3">
                {{ item[header.value][header.value2][header.value3] }}
              </div>

              <div v-else-if="header.value2">
                {{ item[header.value][header.value2] }}
              </div>

              <div
                v-else
                :class="
                  header.cloture == true && item.cloture
                    ? 'clotured'
                    : header.expire == true && item.expire
                    ? 'expired'
                    : 'mt-1'
                "
              >
                {{ item[header.value] }}
                <p
                  class="mt-1 mb-0"
                  style="
                    white-space: pre-line;
                    font-style: italic;
                    font-size: 13px;
                  "
                  v-if="header.description"
                >
                  {{ item[header.description] }}
                </p>
              </div>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item>
          <td :colspan="headers.length">
            <v-data-table
              :headers="exp_headers"
              hide-default-footer
              :hide-default-header="hdh_exp"
              :items="exp_data"
              :items-per-page="-1"
              dense
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td
                    valign="middle"
                    v-for="(header, columnIndex) in exp_headers.filter(
                      (elm) => elm.hiden != true
                    )"
                    :key="columnIndex"
                  >
                    <div
                      class="green--text text-h6"
                      v-if="header.slot == 'numberedit'"
                      align="right"
                    >
                      <v-edit-dialog
                        :return-value.sync="item[header.value]"
                        large
                        persistent
                      >
                        <div>
                          {{
                            item[header.value]
                              ? numberWithSpace(
                                  parseFloat(item[header.value]).toFixed(
                                    typeof header.dec == "number"
                                      ? header.dec
                                      : typeof header.dec == "string"
                                      ? item[header.dec]
                                      : 2
                                  )
                                )
                              : parseFloat("0").toFixed(
                                  typeof header.dec == "number"
                                    ? header.dec
                                    : typeof header.dec == "string"
                                    ? item[header.dec]
                                    : 2
                                )
                          }}
                        </div>
                        <template v-slot:input>
                          <div class="mt-4 text-h6">
                            {{
                              item[header.value]
                                ? numberWithSpace(
                                    parseFloat(item[header.value]).toFixed(
                                      typeof header.dec == "number"
                                        ? header.dec
                                        : typeof header.dec == "string"
                                        ? item[header.dec]
                                        : 2
                                    )
                                  )
                                : parseFloat("0").toFixed(
                                    typeof header.dec == "number"
                                      ? header.dec
                                      : typeof header.dec == "string"
                                      ? item[header.dec]
                                      : 2
                                  )
                            }}
                          </div>
                          <v-text-field
                            autocomplete="off"
                            v-model="item[header.value]"
                            label="Edit"
                            single-line
                            @focus="$event.target.select()"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </div>
                    <div
                      v-else-if="header.slot == 'href22'"
                      align="right"
                      class="cur4"
                    >
                      <a
                        @click="
                          editItem2(
                            item,
                            index,
                            item[header.value],
                            header.value
                          )
                        "
                      >
                        {{
                          numberWithSpace(
                            parseFloat(
                              item[header.value] ? item[header.value] : 0
                            ).toFixed(
                              typeof header.dec == "number"
                                ? header.dec
                                : typeof header.dec == "string"
                                ? item[header.dec]
                                : 2
                            )
                          )
                        }}
                      </a>
                    </div>
                    <div v-else-if="header.slot == 'cur'" align="right">
                      {{
                        typeof item[header.value] == "number"
                          ? numberWithSpace(
                              parseFloat(item[header.value]).toFixed(
                                typeof header.dec == "number"
                                  ? header.dec
                                  : typeof header.dec == "string"
                                  ? item[header.dec]
                                  : 2
                              )
                            )
                          : parseFloat("0").toFixed(
                              typeof header.dec == "number"
                                ? header.dec
                                : typeof header.dec == "string"
                                ? item[header.dec]
                                : 2
                            )
                      }}
                    </div>
                    <div v-else>
                      {{ item[header.value] }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
        <template slot="body.append">
          <tr v-if="Total">
            <td v-if="multiple"></td>
            <td v-if="showexp"></td>
            <td
              v-for="(header, colIndex) in selection"
              :key="colIndex"
              style="text-align: right"
            >
              <div v-if="header.totlib" align="left">
                Total ({{
                  search ? filtereditems.length : items ? items.length : 0
                }})
              </div>
              <div v-else-if="header.total" align="right" class="cur3">
                {{
                  header.total != null
                    ? sumCol(header.total) != null
                      ? numberWithSpace(
                          parseFloat(sumCol(header.total)).toFixed(
                            typeof header.dec == "number"
                              ? header.dec
                              : typeof header.dec == "string"
                              ? item[header.dec]
                              : 2
                          )
                        )
                      : numberWithSpace(
                          parseFloat(header.total).toFixed(
                            typeof header.dec == "number"
                              ? header.dec
                              : typeof header.dec == "string"
                              ? item[header.dec]
                              : 2
                          )
                        )
                    : ""
                }}
              </div>
            </td>
          </tr>
          <tr v-if="multiple">
            <td v-if="multiple"></td>
            <td
              v-for="(header, colIndex) in selection"
              :key="colIndex"
              style="text-align: right"
            >
              <div v-if="header.totlib" align="left">
                Selections ({{ selected_list.length }})
              </div>
              <div v-else-if="header.total" align="right" class="cur3">
                {{
                  numberWithSpace(
                    parseFloat(sumSelect(header.total)).toFixed(
                      typeof header.dec == "number"
                        ? header.dec
                        : typeof header.dec == "string"
                        ? item[header.dec]
                        : 2
                    )
                  )
                }}
              </div>
            </td>
          </tr>
        </template>
        <template slot="footer.prepend" v-if="doc_download">
          <v-progress-linear :value="progress_value" height="25">
            <template v-slot:default>
              <strong>{{ progress_value }}%</strong>
            </template>
          </v-progress-linear>
        </template>
      </v-data-table>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <formstd
      :item="editeditem"
      :items="items"
      :qCreate="qCreate"
      :qUpdate="qUpdate"
      :headers="form_headers"
      :Get_suf="Get_suf"
      :key="keyform"
      :Update="form_update"
      :showForm="!isClosed2"
      @close="closeForm"
      @add="sendadd"
      @change="sendchange"
      :title="title_form"
      :push="push"
    >
    </formstd>
  </div>
</template>

<script>
//import { exportToXLSX } from "../utils/export";
import Sortable from "sortablejs";

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        const currentClassState =
          mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add("sortHandle");
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export default {
  name: "listitems",
  props: {
    qSelect: Object,
    qCreate: Object,
    qUpdate: Object,
    qDelete: Object,
    Get_suf: String,
    list: Array,
    data_ext: { type: Boolean, default: false },
    title: String,
    headers: Array,
    master: { type: Boolean, default: true },
    groupby: { type: String, default: null },
    add: { type: Boolean, default: true },
    Update: { type: Boolean, default: false },
    del: { type: Boolean, default: true },
    print: { type: Boolean, default: false },
    valid: { type: Boolean, default: false },
    add_disable: { type: Boolean, default: true },
    del_disable: { type: Boolean, default: false },
    Total: { type: Boolean, default: false },
    showexp: { type: Boolean, default: false },
    hdh_exp: { type: Boolean, default: true },
    exp_headers: Array,
    exp_data: Array,
    secondligne: Array,
    filename: String,
    sheetname: String,
    showedit: { type: Boolean, default: true },
    showstd: { type: Boolean, default: false },
    push: { type: Boolean, default: false },
    VarScope: Number,
    selitem: Object,
    search_elm: String,
    laoding: { type: Boolean, default: false },
    ligne: { type: Number, default: 0 },
    multiple: { type: Boolean, default: false },
    exp_excel: { type: Boolean, default: true },
    imp_excel: { type: Boolean, default: false },
    btn_update: { type: Boolean, default: false },
    btn_update_icon: { type: String, default: "mdi-pencil" },
    btn_update_tip: { type: String, default: "Modifier" },
    loading_del: { type: Boolean, default: false },
    kid: { type: String, default: "id" },
    ipg: { type: Number, default: 10 },
    disablesort: { type: Boolean, default: false },
    elevation: { type: Number, default: 1 },
    total_items: { type: Number, default: -1 },
    page: { type: Number, default: 0 },
    hdf: { type: Boolean, default: false },
    istop: { type: Boolean, default: true },
    val_exp: String,
    mes_exp: String,
    maxNumberOfChoices: { type: Number, default: 0 },
    show_chips_delete: { type: Boolean, default: false },
    btnA: String,
    btnA_disa: { type: Boolean, default: false },
    btnA_load: { type: Boolean, default: false },
    if_A: { type: Boolean, default: false },
    btnB: String,
    btnB_disa: { type: Boolean, default: false },
    btnB_load: { type: Boolean, default: false },
  },
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    formstd: () => import("../components/FormStd.vue"),
    downloadcomp: () => import("../components/DownloadComp.vue"),
    UploadExcelComponent: () => import("../components/UploadExcel.vue"),
    datepicker: () => import("./DatePicker.vue"),
  },

  data: () => ({
    row_key: 4235452,
    Item_list_selected: null,
    menu: null,
    color: null,
    valid2: true,
    form_update: false,
    downloadLoading: false,
    doc_download: false,
    progress_value: 0,
    options: {},
    fs: 100,
    fab: false,
    klist: 200,
    kc: 250,
    selecteditem: {},
    editeditem: {},
    selectedRows: [],
    expanded: [],
    form_headers: [],
    search: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    items: [],
    filtereditems: [],
    selected_list: [],
    Loading: false,
    isClosed: true,
    isClosed2: true,
    title_form: "",
  }),

  computed: {
    validationRules() {
      return this.maxNumberOfChoices ? [this.validateMaxNumberOfChoices] : [];
    },
    swatchStyle() {
      return {
        backgroundColor: this.color,
        border: this.color ? "" : "solid",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    keyform() {
      return this.fs;
    },
    selection() {
      return this.headers.filter((item) => {
        if (item.selected === true && item.hiden != true) {
          return item;
        }
      });
    },
    json_data() {
      let data = [];
      if (this.items.length > 0)
        for (let index = 0; index < this.items.length; index++) {
          let item = {};

          this.selection.forEach((element) => {
            if (element.value == "no") {
              item.no = index + 1;
            } else item[element.value] = this.items[index][element.value];
          });
          data.push(item);
        }
      return data;
    },
  },
  watch: {
    // selitem: {
    //   handler() {
    //     if (this.selitem) {
    //       let i = this.items.findIndex((elm) => elm.id == this.selitem.id);
    //       if (i >= 0) {
    //         this.selecteditem = this.items[i];
    //         // this.$emit("rowselect", this.selecteditem);
    //       } else this.$emit("rowselect", {});
    //     }
    //   },
    // },
    options: {
      handler() {
        this.$emit("options", this.options);
      },
      deep: true,
    },
    search: {
      handler() {
        this.$emit("search", this.search);
      },
      deep: true,
    },
  },
  created() {},
  // beforeDestroy() {
  //document.removeEventListener("keydown", this._keyListener);

  //  },

  mounted() {
    /*   this._keyListener = function (e) {
      //if (e.key === "+" && (e.altKey || e.metaKey)) {
      if (e.key === "+" && this.add && this.add_disable) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        this.itemnew();
      } else if (
        e.key === "-" &&
        this.del &&
        !this.del_disable &&
        this.selecteditem.id
      ) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        this.delRecord();
      } else if (e.key === "e" && (e.altKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        if (this.$refs.excel) this.$refs.excel.$el.click();
      }
    };*/
    this.search = this.search_elm;
    // document.addEventListener("keydown", this._keyListener.bind(this));
    if (this.qSelect) {
      this.Loading = true;
      this.$apollo
        .query({
          query: this.qSelect,
          variables: {
            VarScope: this.VarScope,
          },
        })

        .then((data) => {
          this.items = data.data[this.Get_suf.concat("s")];
          this.Loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.Loading = false;
        });
    } else {
      this.items = this.list;
    }

    this.options.page = Math.floor(this.ligne / this.options.itemsPerPage + 1);

    if (this.selitem) {
      let i = this.items.findIndex((elm) => elm.id == this.selitem.id);

      if (i >= 0) {
        this.selecteditem = this.items[i];
      }
    } else if (this.items)
      if (
        this.items[this.ligne] != "undefined" &&
        this.items[this.ligne] != "" &&
        this.items[this.ligne] != null &&
        !this.multiple
      ) {
        this.rowClicked(this.items[this.ligne], this.ligne);
      }
  },

  methods: {
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.selection;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
      this.row_key++;
    },

    validateMaxNumberOfChoices(selectedChoices) {
      return (
        selectedChoices.length <= this.maxNumberOfChoices ||
        `Choisir ${this.maxNumberOfChoices} au maximum.`
      );
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    colinput() {
      if (typeof this.color === "object") this.color = this.color.hexa;
    },
    datefr(date) {
      let d;
      if (date) {
        var result = new Date(date);
        d = result.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      }
      return d;
    },
    getFiltered() {
      if (this.search)
        this.filtereditems = this.$refs.refName.$children[0].filteredItems;
    },
    file_uploaded(file, field) {
      this.$emit("file_uploaded", file, field);
    },
    file_deleted(field) {
      this.$emit("file_deleted", field);
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 4;
      if (isLt1M) {
        return true;
      }
      this.snackbar_text = "Please do not upload files larger than 4m in size.";
      this.snackbar_color = "warning";
      this.snackbar = true;
      return false;
    },
    handleSuccess({ results, header }) {
      let items = {
        headers: header,
        results: results,
        headersequal: arraysEqual(
          this.selection.map((elm) => elm.text),
          header
        ),
      };

      this.$emit("excel_import", items);
    },
    handleDownload() {
      this.downloadLoading = true;
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;
      if (this.data_ext) {
        this.$emit("excel_download");
        return;
      }
      // const headers = {};

      // this.selection.forEach((element) => {
      //   headers[element.value] = element.text;
      // });

      // let data = [];

      // this.json_data.forEach((element) => {
      //   let obj = {};

      //   this.selection.forEach((element2) => {
      //     obj[element2.value] = element[element2.value];
      //   });
      //   data.push(obj);
      // });

      // exportToXLSX(data, this.filename + ".xlsx", headers, [7, 8]);
      import("@/utils/export").then((excel) => {
        const tHeader = this.selection.map((elm) => elm.text);
        const filterVal = this.selection.map((elm) => elm.value);
        const list = this.json_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: true,
          bookType: "xlsx",
        });
      });
      this.downloadLoading = false;
      this.snackbar_text = "Chargement terminé";
      this.snackbar_color = "success";
      this.snackbar = true;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },

    /*
    addFiles() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.files.click();
    },
    onChange() {
      this.file = this.$refs.files.files[0];
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.$emit("excel_import", data);
        };

        reader.readAsBinaryString(this.file);
      }
    },*/
    select_all(props) {
      if (
        props.items.length === this.items.filter((elm) => elm.selected).length
      ) {
        props.items.forEach((element) => {
          element.selected = false;
        });
      } else {
        props.items.forEach((element) => {
          element.selected = true;
        });
      }
      this.selected_list = props.items.filter((elm) => elm.selected);
      this.$emit("selected_rows", this.selected_list);
    },
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    sumCol(key) {
      let list = this.search ? this.filtereditems : this.items;
      // sum data in give key (property)
      return list ? list.reduce((a, b) => a + parseFloat(b[key] || 0), 0) : 0;
    },

    sumSelect(key) {
      return this.selected_list.reduce((a, b) => a + (b[key] || 0), 0);
    },

    clickhrf(item, index, col) {
      item.index = index;
      item.col = col;
      this.editeditem = item;
      this.$emit("hrf", this.editeditem);
      if (this.showstd) {
        this.editItem(item, index);
      }
    },
    chips_click(item) {
      this.$emit("chips_click", item);
    },
    chips_delete(item, chip) {
      this.$emit("chips_delete", item, chip);
    },
    btnclick(item) {
      this.$emit("btn_click", item);
    },
    col_btn1_click(item) {
      this.$emit("col_btn1_click", item);
    },
    col_btn2_click(item) {
      this.$emit("col_btn2_click", item);
    },
    col_btn3_click(item) {
      this.$emit("col_btn3_click", item);
    },
    col_btn4_click(item) {
      this.$emit("col_btn4_click", item);
    },
    dropdownclick(item, item2) {
      this.$emit("dd_click", item, item2);
    },
    sendadd(item) {
      this.$emit("add", item);
    },
    sendchange(item) {
      this.$emit("change", item);
    },
    valider() {
      this.$emit("valider");
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    closeForm() {
      this.editeditem = {};
      this.isClosed = true;
      this.isClosed2 = true;
    },
    rowClicked(row, index) {
      if (row) row.index = index;
      if (row.disabled != true) {
        if (row.selected) row.selected = false;
        else row.selected = true;
      }
      this.selecteditem = row;
      this.selected_list = this.items.filter((elm) => elm.selected);
      this.$emit("selected_rows", this.selected_list);
      this.$emit("rowselect", this.selecteditem);
    },
    checkbox_change() {
      this.selected_list = this.items.filter((elm) => elm.selected);
      this.$emit("selected_rows", this.selected_list);
    },
    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },
    print_document() {
      this.$emit("print");
    },
    btn_update_click() {
      this.$emit("btn_update_click", this.selecteditem);
    },
    itemnew() {
      this.editeditem = { id: -1 };
      this.headers
        .filter((elm) => elm.edit == true)
        .forEach((elm) => {
          if (elm.default) {
            this.editeditem[elm.value] = elm.default;
            if (elm.type == "color") this.color = elm.default;
          }
          if (elm.qte_max) {
            this.editeditem.qte_max = elm.qte_max;
          }
          if (elm.qte_min) {
            this.editeditem.qte_min = elm.qte_min;
          }
        });

      if (this.selecteditem) this.editeditem.index = this.selecteditem.index;
      this.fs++;
      if (this.showstd) {
        this.title_form = this.title;
        this.form_headers = this.headers;
        this.form_update = this.Update;
        this.isClosed2 = false;
        if (!this.items) this.items = [];
      } else if (this.showedit) {
        this.title_form = this.title;
        this.isClosed = false;
        if (!this.items) this.items = [];
      } else this.$emit("open", this.editeditem);
    },
    editItem2(item, index) {
      item.index = index;
      this.editeditem = item;
      this.exp_headers
        .filter((elm) => elm.edit == true)
        .forEach((elm) => {
          if (elm.qte_max) {
            this.editeditem.qte_max = elm.qte_max;
          }
          if (elm.qte_min) {
            this.editeditem.qte_min = elm.qte_min;
          }
          this.title_form = elm.title;
        });

      this.form_headers = this.exp_headers;
      this.form_update = true;
      this.fs++;
      this.isClosed2 = false;
    },
    editItem(item, index, title, col) {
      item.index = index;
      item.col = col;
      this.editeditem = item;
      this.headers
        .filter((elm) => elm.edit == true)
        .forEach((elm) => {
          if (elm.qte_max) {
            this.editeditem.qte_max = elm.qte_max;
          }
          if (elm.qte_min) {
            this.editeditem.qte_min = elm.qte_min;
          }
        });

      this.fs++;
      if (
        this.showstd &&
        (this.editeditem.bylist == "0" || !this.editeditem.bylist)
      ) {
        this.title_form = this.title
          ? this.title
          : typeof title == "number"
          ? title.toString()
          : title;
        this.form_headers = this.headers;
        this.form_update = this.Update;
        this.isClosed2 = false;
        if (!this.items) this.items = [];
      } else if (
        this.showedit &&
        (this.editeditem.bylist == "0" || !this.editeditem.bylist)
      ) {
        this.isClosed = false;
        this.title_form = this.title
          ? this.title
          : typeof title == "number"
          ? title.toString()
          : title;
      } else this.$emit("open", this.editeditem);
    },
    save() {
      let variables = {};
      let s = {};
      this.headers
        .filter((elm) => elm.edit == true)
        .forEach((elm) => {
          if (elm.type == "select" || elm.type == "autocomplete") {
            this.editeditem[elm.value] = this.editeditem[elm.value]
              ? this.editeditem[elm.value].toString()
              : "";
            s[elm.value] = this.editeditem[elm.value];
          } else if (elm.type == "color") {
            s[elm.value] = this.color;
            this.editeditem[elm.value] = this.color;
            s[elm.value] = this.editeditem[elm.value];
          }
          if (elm.type == "number" && elm.type_base == "string") {
            s[elm.value] = this.editeditem[elm.value].toString();
            this.editeditem[elm.value] = this.color;
            s[elm.value] = this.editeditem[elm.value];
          } else if (elm.type == "check-box") {
            if (this.editeditem[elm.value]) s[elm.value] = 1;
            else s[elm.value] = 0;
          } else {
            s[elm.value] = this.editeditem[elm.value];
          }
        });
      s["write_uid"] = this.$store.state.me.id;
      if (this.$refs.form.validate()) {
        this.progress = true;

        if (this.editeditem.id > 0) {
          s["id"] = this.editeditem.id;
          if (this.qUpdate) {
            variables[this.Get_suf.toLowerCase()] = s;
            this.$apollo
              .mutate({
                mutation: this.qUpdate,
                variables,
              })
              .then(() => {
                this.items.splice(
                  this.items.findIndex((e) => e.id == this.editeditem.id),
                  1,
                  this.editeditem
                );
                this.$emit("change", this.editeditem);
                this.progress = false;
              })
              .catch((error) => {
                this.loading = false;
                this.snackbar_text = error.message;
                this.snackbar_color = "error";
                this.snackbar = true;
              });
          }

          this.$emit("close", this.editeditem);
        } else {
          s["create_uid"] = this.$store.state.me.id;
          if (this.qCreate) {
            variables[this.Get_suf.toLowerCase()] = s;

            this.$apollo
              .mutate({
                mutation: this.qCreate,
                variables,
              })
              .then((data) => {
                this.progress = false;
                this.editeditem.id =
                  data.data["create".concat(this.Get_suf)].id;
                if (this.push) this.items.push(this.editeditem);
                else this.items.unshift(this.editeditem);
                this.$emit("close", this.editeditem);
                //this.$store.dispatch("Changed", true);
                this.$emit("add", this.editeditem);
              })
              .catch((error) => {
                this.loading = false;
                this.snackbar_color = "error";
                this.snackbar_text = error.message;
                this.snackbar = true;
              });
          } else {
            this.editeditem.id = this.items.length + 1;
            s["id"] = this.editeditem.id;
            if (this.push) this.items.push(s);
            else this.items.unshift(s);
            this.$emit("close", s);
            //this.$store.dispatch("Changed", true);
            this.$emit("sendadd", s);
          }
        }
        this.isClosed = true;
      }
    },
    routeto(item, index, to) {
      item.index = index;
      this.$router.push({ name: to, params: { item: item } });
    },
    async delRecord() {
      if (this.qDelete || this.showedit) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cet element?"
          )
        ) {
          if (this.qDelete)
            this.$apollo
              .mutate({
                mutation: this.qDelete,
                variables: {
                  id: this.selecteditem[this.kid],
                },
              })
              .then(() => {
                let i = this.items.findIndex(
                  (elm) => elm.id == this.selecteditem.id
                );
                if (i >= 0) this.items.splice(i, 1);
                this.$emit("delete", this.selecteditem);
                this.selecteditem = {};
                this.klist++;
              })
              .catch((error) => {
                this.snackbar_color = "error";
                this.snackbar_text = error.message;
                this.snackbar = true;
              });
          else if (this.showedit) {
            let i = this.items.findIndex(
              (elm) => elm.id == this.selecteditem.id
            );
            if (i >= 0) this.items.splice(i, 1);
            this.selecteditem = {};
            this.$emit("delete", this.selecteditem);
          }
        }
      } else this.$emit("delete", this.selecteditem);
    },

    clickA() {
      this.$emit("clickA");
    },
    clickB() {
      this.$emit("clickB");
    },
    search_click() {
      this.$emit("search_click");
    },
  },

  directives: {
    "sortable-table": {
      inserted: (el, binding) => {
        el.querySelectorAll("th").forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, "sortHandle");
          draggableEl.classList.add("sortHandle");
        });
        Sortable.create(
          el.querySelector("tr"),
          binding.value ? { ...binding.value, handle: ".sortHandle" } : {}
        );
      },
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/default.scss";
</style>
